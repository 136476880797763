import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"

import styles from "./join-us.module.css"

export default ({ data }) => {
  const jobs = data.allMarkdownRemark.edges.filter(
    ({ node }) => node.frontmatter.posttype === "job"
  )
  return (
    <Layout>
      <SEO title="Join Us" />
      <div className={styles.container}>
        <div className={styles.introDetails}>
          <div className={styles.introDetails1} />
          <div className={styles.introDetails2} />
          <div className={styles.introDetails3} />
        </div>

        <div className={styles.hero}>
          <h2 className={styles.title}>OUR GOAL</h2>
          <h3 className={styles.subtitle}>
            A <span className={styles.underline}>human first</span> approach to
            the digital world
          </h3>
          <p className={styles.small_text}>
            We are a product studio helping startups and growing businesses
            build & evolve their digital products through design, user
            experiences and technology.
          </p>
          <a className={styles.cta} href="">
            SEE OPEN POSITIONS &#8594;
          </a>
        </div>

        <div className={styles.main}>
          <h2 className={styles.title}>WHO WE ARE</h2>
          <h3 className={styles.subtitle}>
            Our mission is to create purposeful digital products & experiences
          </h3>
          <p className={styles.mid_text}>
            We’re a team of UX/UI designers & developers acting as your
            technical and product co-founders rather than an outsourced team. We
            believe in collaborating & working closely with you on product
            design and development to create the new digital world. All ideas
            are different and we treat them that way.
          </p>
        </div>

        <div className={styles.main}>
          {jobs.map(({ node }) => {
            return (
              <div key={node.id} className={styles.position_row}>
                <div className={styles.position_row__details}>
                  <h2 className={styles.description}>AVAILABLE POSITION</h2>
                  <h4 className={styles.position}>{node.frontmatter.title}</h4>
                </div>
                <div className={styles.position_row__more}>
                  <div className={styles.slash}></div>
                  <a className={styles.cta} href={node.fields.slug}>
                    LEARN MORE
                  </a>
                </div>
              </div>
            )
          })}
        </div>

        <div className={styles.main}>
          <p className={styles.mid_text}>
            Don’t see the perfect role for you?{" "}
            <a href="mailto:siebe@thisisundefined.com">Drop us a line</a> and
            we’ll be in touch when we add new positions.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            posttype
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
